import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, catchError, concat, filter, finalize, map, mergeMap, Observable, of, switchMap, take, tap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAuthModel } from '../models/auth.model';
import { UserModel } from '../models/user.model';
import { UserDetails } from '../../users/models/user-details.model';
import jwt_decode from 'jwt-decode';
import rg4js from 'raygun4js';

const API_ACCOUNT_URL = `${environment.apiUrl}/account`;
const API_AUTH_URL = `${environment.apiUrl}/auth`;

export type UserType = UserModel | undefined;

@Injectable({ providedIn: 'root' })
export class AuthService {

  private readonly _authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private readonly _currentUser$ = new BehaviorSubject<UserType>(undefined);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  public readonly lastAuthenticated = signal<Date | undefined>(undefined);
  public readonly currentUser$ = this._currentUser$.asObservable();
  public readonly isLoading$ = this._isLoading$.asObservable();

  get currentUser(): UserType {
    return this._currentUser$.value;
  }

  constructor(
    private readonly _http: HttpClient,
    private readonly _logger: NGXLogger,
    private readonly _router: Router
  ) {
    // If the user is authenticated, start a timer to refresh the token before it expires
    this._bindRefreshTimer();
  }

  private _bindRefreshTimer() {
    timer(0, 1000 * 60 * 14)
      .pipe(
        filter(() => !!this.currentUser),
        mergeMap(() => this.refreshToken())
      )
      .subscribe(() => {
        this._logger.debug('[AuthService][refreshTime] refreshed');
      });
  }

  private _getAuthFromLocalStorage(): IAuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this._authLocalStorageToken);
      if (!lsValue) {
        this._logger.debug('[AuthService][getAuthFromLocalStorage] no auth in local storage');
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      this._logger.error('[AuthService][getAuthFromLocalStorage]', error);
      return undefined;
    }
  }

  private _getUserByToken(): Observable<UserType> {
    const auth = this._getAuthFromLocalStorage();
    if (!auth?.authToken) {
      return of(undefined);
    }

    this._isLoading$.next(true);

    return this._http.get<UserModel>(`${API_AUTH_URL}/me`).pipe(
      map((user: UserType) => {
        if (user) {
          const decodedToken: any = jwt_decode(auth.authToken);
          let roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
          if (!Array.isArray(roles)) { roles = [roles]; }
          user.scopes = roles;

          rg4js('setUser', {
            identifier: decodedToken.sub,
            isAnonymous: false,
            email: user.email,
            fullName: user.displayName
          });

          this._logger.debug('[AuthService][getUserByToken] user', user);

          this._currentUser$.next(user);
        } else {
          this._logger.debug('[AuthService][getUserByToken] undefined');
          this.signOut();
        }
        return user;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  private _setAuthFromLocalStorage(authData: Partial<IAuthModel>): boolean {
    this._logger.debug('[AuthService][setAuthFromLocalStorage]', authData);

    if (!!authData && !!authData.authToken) {
      localStorage.setItem(this._authLocalStorageToken, JSON.stringify(authData));
      return true;
    }

    return false;
  }

  changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this._http.post<undefined>(`${API_AUTH_URL}/change-password`, { currentPassword, newPassword });
  }

  clearTokens(): void {
    this._logger.debug('[AuthService][clearTokens]');
    localStorage.removeItem(this._authLocalStorageToken);
    this._currentUser$.next(undefined);
  }

  confirmEmailChange(userId: string, email: string, token: string): Observable<void> {
    return this._http.post<undefined>(`${API_ACCOUNT_URL}/email/confirm-change`, { userId, email, token });
  }

  forgotPassword(email: string): Observable<void> {
    this._isLoading$.next(true);

    return this._http.post<undefined>(`${API_AUTH_URL}/forgot-password`, {
      email
    }).pipe(finalize(() => this._isLoading$.next(false)));
  }

  getAuthToken(): string {
    return this._getAuthFromLocalStorage()?.authToken ?? '';
  }

  async getAuthTokenAsync(): Promise<string> {
    this._logger.debug('[AuthService].[getAuthTokenAsync]');

    const storedToken = this._getAuthFromLocalStorage();

    if (!!storedToken?.expiresIn) {
      const now = new Date();
      const oneMinuteFromNow = new Date(now.getTime() + 60 * 1000);

      if (storedToken.expiresIn >= oneMinuteFromNow) {
        this._logger.debug('[AuthService].[getAuthTokenAsync] existing token is still valid');
        return storedToken.authToken;
      }

      return await new Promise<string>((resolve, reject) => {
        this._logger.debug('[AuthService].[getAuthTokenAsync] refreshing token');

        this.refreshToken().subscribe({
          next: token => resolve(token.authToken),
          error: error => reject(error)
        });
      });
    }

    this._logger.debug('[AuthService].[getAuthTokenAsync] not authenticated');
    return '';
  }

  getRefreshToken(): string {
    return this._getAuthFromLocalStorage()?.refreshToken ?? '';
  }

  getUser(): Observable<UserType> {
    const user = concat(
      this._currentUser$.pipe(take(1), filter(u => !!u)),
      this._getUserByToken().pipe(take(1), filter(u => !!u)),
      this._currentUser$.asObservable()
    );

    return user;
  }

  getUserDetails(): Observable<UserDetails | null> {
    return this._http.get<UserDetails>(`${API_AUTH_URL}/me/details`);
  }

  isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map(u => !!u));
  }

  hasScope(scope: string): boolean {
    return this._currentUser$.value?.scopes.includes(scope) ?? false;
  }

  reauthenticate(password: string): Observable<boolean> {
    if (!this.currentUser) { return of(false); }

    const authData = this._getAuthFromLocalStorage();
    if (!authData) { return of(false); }

    return this.signIn(this.currentUser.email, password, authData.isPersistent).pipe(
      map(user => !!user)
    );
  }

  refreshToken(): Observable<IAuthModel> {
    return this._http.post<IAuthModel>('/api/auth/refresh', {
      refreshToken: this.getRefreshToken()
    }).pipe(
      tap(tokens => {
        this._setAuthFromLocalStorage(tokens);
      })
    );
  }

  refreshUser(): Observable<UserType> {
    return this._getUserByToken();
  }

  resetPassword(email: string, token: string, password: string): Observable<void> {
    this._isLoading$.next(true);

    return this._http.post<undefined>(`${API_AUTH_URL}/reset-password`, {
      email,
      token,
      password
    }).pipe(finalize(() => this._isLoading$.next(false)));
  }

  signIn(email: string, password: string, isPersistent: boolean): Observable<UserType> {
    this._isLoading$.next(true);

    return this._http.post<IAuthModel>(`${API_AUTH_URL}/signin`, {
      email,
      password,
      isPersistent
    }).pipe(
      map((auth: IAuthModel) => {
        const result = this._setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this._getUserByToken()),
      tap(() => { this.lastAuthenticated.set(new Date()); }),
      catchError((err) => {
        this._logger.error('[AuthService][signIn]', err);
        return of(undefined);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  signInWithToken(refreshToken: string): Observable<UserType> {
    this._isLoading$.next(true);

    this._setAuthFromLocalStorage({
      authToken: 'refreshing',
      refreshToken
    });

    return this.refreshToken()
      .pipe(
        switchMap(() => this._getUserByToken()),
        finalize(() => this._isLoading$.next(false))
      );
  }

  signOut() {
    this._logger.debug('[AuthService][signOut]');

    return this._http.post<undefined>('/api/auth/signout', { refreshToken: this.getRefreshToken() })
      .pipe(
        finalize(() => {
          this.clearTokens();
          this._router.navigate(['/auth/signin']);
        })
      );
  }

}
